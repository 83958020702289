export const wrap = (wrapper: HTMLElement, element: HTMLElement) => {
  element.parentElement?.insertBefore(wrapper, element);
  wrapper.appendChild(element);
};

export const findParent = (
  el: HTMLElement | null,
  predicate: (el: HTMLElement) => boolean
) => {
  if (el == null) return null;
  if (predicate(el)) {
    return el;
  } else {
    return findParent(el.parentElement, predicate);
  }
};
