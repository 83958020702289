












import { Component, Vue, Prop } from 'vue-property-decorator';
import ImageViewer from '@/themes/v1/components/ImageViewer.vue';
import { OnMounted } from '@/core/vue.types';
import TestLocator from '@/core/test-utils/test-locator';
import { wrap } from '@/themes/v1/fns/element-manipulations';

@Component({
  components: { ImageViewer },
  directives: { TestLocator },
})
export default class ProblemContent extends Vue implements OnMounted {
  @Prop({ type: String, required: true })
  content!: string;

  $refs!: {
    container: HTMLDivElement;
    imageViewer: InstanceType<typeof ImageViewer>;
  };

  mounted() {
    this.prepareContent();
  }

  prepareContent() {
    const container = this.$refs.container;
    this.getImages(container).forEach((image) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add(
        'problem-content__image-container',
        'scalable-image'
      );
      wrap(wrapper, image);
      image.classList.add('problem-content__img');
      wrapper.addEventListener('click', () => this.imageClick(image));
    });
  }

  getImages(container: HTMLDivElement) {
    return Array.from<HTMLImageElement>(
      container.getElementsByTagName<any>('img')
    );
  }

  imageClick(image: HTMLImageElement) {
    this.$refs.imageViewer.open([{ src: image.src, description: image.alt }]);
  }
}
