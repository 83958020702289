

























import { Component, Prop, Vue } from 'vue-property-decorator';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import StatusLabel from '@/themes/v1/components/StatusLabel.vue';
import ProblemContent from '@/themes/v1/views/sts/problems/ProblemContent.vue';

@Component({
  components: {
    ProblemContent,
    StatusLabel,
    BoxContent,
  },
})
export default class ProblemMessage extends Vue {
  @Prop()
  status!: string;

  @Prop()
  date!: string;

  @Prop()
  message!: string;
}
