import { render, staticRenderFns } from "./SubscribeOnProblem.vue?vue&type=template&id=53f8f384&scoped=true&"
import script from "./SubscribeOnProblem.vue?vue&type=script&lang=ts&"
export * from "./SubscribeOnProblem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f8f384",
  null
  
)

export default component.exports