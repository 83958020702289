
















































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import { ValidationProvider } from 'vee-validate';
import ControlError from '@/themes/v1/components/ControlError.vue';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  components: { ControlError, BoxContent, Box, ValidationProvider },
  directives: { TestLocator },
})
export default class SubscribeOnProblem extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  email = '';

  @Emit('subscribe')
  onSubscribe() {
    return this.email;
  }
}
